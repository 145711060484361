/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'coupons';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getMerchantCouponConfig: () => api.get(`${path}/configs`),
  setMerchantCouponConfig: (payload) => api.post(`${path}/configs`, payload),
  setImageCouponConfig: (payload) => api.file(`${path}/upload-image`, payload),
  getCouponPlanList: (params) =>
    api.get(`${path}/plans-list?${serialize(params)}`),
  createCouponPlan: (payload) => api.post(`${path}/plan-create`, payload),
  createSingleCoupon: (payload) => api.post(`${path}/single-coupon`, payload),
  sendSingleCoupon: (payload) =>
    api.post(`${path}/send-single-coupon`, payload),
  deleteCouponPlan: (id) => api.post(`${path}/plan-delete/${id}`),

  getCouponList: (params) => api.get(`${path}/list?${serialize(params)}`),
  getCouponPlans: () => api.get(`${path}/plans`),
  getCouponPlanStoreCreditList: () => api.get(`${path}/store-credits`),
  getCouponDiscountsList: () => api.get(`${path}/discounts`),
  resendEmail: (id) => api.post(`${path}/resend/${id}`),
};
