/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'store-credits';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getStoreCreditConfig: () => api.get(`${path}/configs`),
  setStoreCreditConfig: (payload) => api.post(`${path}/configs`, payload),
  getStoreCreditPlans: () => api.get(`${path}/plans`),
  getStoreCreditsList: (params) => api.get(`${path}/list?${serialize(params)}`),
  getStoreCreditsRedemptionsList: (params) =>
    api.get(`${path}/redemptions-list?${serialize(params)}`),
  clearStoreCreditsBalance: (payload) => api.post(`${path}/clear`, payload),
  syncStoreCredits: (payload) => api.post(`${path}/sync`, payload),
  importStoreCredits: (payload) => api.post(`${path}/import`, payload),
  issueStoreCredit: (payload) => api.post(`${path}/issue`, payload),
  deductGiftcard: (payload) => api.post('gift-cards/deduct', payload),
  addFundGiftCard: (payload) => api.post('gift-cards/add-funds', payload),
};
