/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'gift-cards';
const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  setGiftCardConfig: (payload) => api.post(`${path}`, payload),
  getGiftCardConfig: (giftCardId) => api.get(`${path}/${giftCardId}`),
  getGiftCardConfigList: (params) => api.get(`${path}?${serialize(params)}`),
  setGiftCardImage: (payload) => api.file(`${path}/creativeimage`, payload),
  getLanguages: () => api.get(`${path}/languages`),
  // GiftCardTerms
  getGiftCardTermsByGiftCardConfig: (giftCardConfigId) =>
    api.get(`${path}/${giftCardConfigId}/gift-card-terms`),
  setGiftCardTerms: (giftCardConfigId, payload) =>
    api.post(`${path}/${giftCardConfigId}/gift-card-terms`, payload),
  updateStatusGiftCardTerms: (occasionId, giftCardConfigId, payload) =>
    api.post(
      `${path}/${giftCardConfigId}/gift-card-terms/${occasionId}/update-status`,
      payload
    ),

  updateStatusGiftCardConfig: (id, payload) =>
    api.post(`${path}/${id}/update-status`, payload),

  setDefaultGiftCardConfig: (id) => api.post(`${path}/${id}/set-default`),
  // GiftCardImages
  getGiftCardImages: (giftCardConfigId) =>
    api.get(`${path}/${giftCardConfigId}/gift-card-images`),
  setGiftCardImagesByGiftCardConfig: (giftCardConfigId, payload) =>
    api.post(`${path}/${giftCardConfigId}/gift-card-images`, payload),
  updateStatusGiftCardImages: (occasionId, giftCardConfigId, payload) =>
    api.post(
      `${path}/${giftCardConfigId}/gift-card-images/${occasionId}/update-status`,
      payload
    ),
  // Occasions
  getOccasionsByGiftCardConfig: (giftCardConfigId) =>
    api.get(`${path}/${giftCardConfigId}/occasions`),
  updateStatusOccasions: (occasionId, giftCardConfigId, payload) =>
    api.post(
      `${path}/${giftCardConfigId}/occasions/${occasionId}/update-status`,
      payload
    ),
  setOccasions: (giftCardConfigId, payload) =>
    api.post(`${path}/${giftCardConfigId}/occasions`, payload),
  setGiftCardImages: (id) => api.get(`${path}/images/${id}`),
  issuePhysicalGiftCard: (payload) =>
    api.post(`${path}/physical/issue`, payload),
  getPhysicalGiftCardBalance: (code) => api.get(`${path}/balance/${code}`),
  updateGiftCardTermDescription: (occasionId, payload) =>
    api.post(`${path}/gift-card-terms/${occasionId}/update`, payload),
  getGiftCardRedeemTransactions: (params) =>
    api.get(`${path}/redemptions?${serialize(params)}`),
  deleteGiftCardImage: (giftCardConfigId, imageId) =>
    api.deleteRequest(`${path}/${giftCardConfigId}/image/${imageId}`),
  updateGiftCardImages: (giftCardConfigId, payload) =>
    api.put(`${path}/${giftCardConfigId}/image`, payload),
  updateOccasions: (giftCardConfigId, payload) =>
    api.put(`${path}/${giftCardConfigId}/occasion`, payload),
  deleteOccasions: (id) => api.deleteRequest(`${path}/occasion/${id}`),
  deleteCardTerm: (id) => api.deleteRequest(`${path}/gift-card-term/${id}`),
  updateGiftCardUserInfo: (payload) => api.put(`${path}/user-info`, payload),
};
