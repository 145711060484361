/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import { getOr } from 'lodash/fp';

const baseUrl = `${process.env.GATSBY_API_URL}`;

let store = {};

export const setStore = (data) => {
  store = data;
};

const authHeader = () => {
  const state = store.getState();
  const user = getOr(false, ['auth', 'user'], state);
  // return auth header with jwt if user is logged in
  const token = getOr(null, 'jwtToken', user);
  const isLoggedIn = !!(user && token);
  if (isLoggedIn) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

function handleResponse(response) {
  const state = store.getState();
  const user = getOr(false, ['auth', 'user'], state);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // logout();
        // console.log('logout with 401,403');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

const get = (url) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};

const post = (url, body = {}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res)
  );
};
const del = (url, body = {}) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res)
  );
};

const file = (url, body = {}) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    credentials: 'include',
    body,
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res)
  );
};

const put = (url, body) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};

const deleteRequest = (url) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    credentials: 'include',
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res)
  );
};

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

const fetchWrapper = {
  get,
  post,
  put,
  delete: del,
  file,
  serialize,
  deleteRequest,
};

export default fetchWrapper;
