/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';

const name = 'giftCard';

const getGiftCardConfig = createAsyncThunk(
  'giftCard/getGiftCardConfig',
  async (payload) => {
    const response = await api.getGiftCardConfig(payload);
    return response;
  }
);

const setGiftCardConfig = createAsyncThunk(
  'giftCard/setGiftCardConfig',
  async (payload) => {
    const response = await api.setGiftCardConfig(payload);
    return response;
  }
);

const setGiftCardImage = createAsyncThunk(
  'giftCard/setGiftCardImage',
  async (payload) => {
    const response = await api.setGiftCardImage(payload);

    return response;
  }
);

const getGiftCardConfigList = createAsyncThunk(
  'giftCard/getGiftCardConfigList',
  async (limit) => {
    const response = await api.getGiftCardConfigList(limit);
    return response;
  }
);

const getLanguages = createAsyncThunk('giftCard/getLanguages', async () => {
  return api.getLanguages();
});
const getOccasionsByGiftCardConfig = createAsyncThunk(
  'giftCard/getOccasionsByGiftCardConfig',
  async (giftCardConfigId) => {
    return api.getOccasionsByGiftCardConfig(giftCardConfigId);
  }
);
const getGiftCardTermsByGiftCardConfig = createAsyncThunk(
  'giftCard/getGiftCardTermsByGiftCardConfig',
  async (giftCardConfigId) => {
    return api.getGiftCardTermsByGiftCardConfig(giftCardConfigId);
  }
);
const setGiftCardTerms = createAsyncThunk(
  'giftCard/setGiftCardTerms',
  async (payload) => {
    const response = await api.setGiftCardTerms(
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);

const setGiftCardImages = createAsyncThunk(
  'giftCard/setGiftCardImages',
  async (payload) => {
    const response = await api.setGiftCardImagesByGiftCardConfig(
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);

const updateStatusGiftCardImages = createAsyncThunk(
  'giftCard/updateStatusGiftCardImages',
  async (payload) => {
    const response = await api.updateStatusGiftCardImages(
      payload.id,
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);

const updateStatusGiftCardTerms = createAsyncThunk(
  'giftCard/updateStatusGiftCardTerms',
  async (payload) => {
    const response = await api.updateStatusGiftCardTerms(
      payload.id,
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);

const updateStatusGiftCardConfig = createAsyncThunk(
  'giftCard/updateStatusGiftCardConfig',
  async (payload) => {
    const response = await api.updateStatusGiftCardConfig(payload.id, payload);
    return response;
  }
);

const setDefaultGiftCardConfig = createAsyncThunk(
  'giftCard/setDefaultGiftCardConfig',
  async (id) => {
    const response = await api.setDefaultGiftCardConfig(id);
    return response;
  }
);

const updateStatusOccasions = createAsyncThunk(
  'giftCard/updateStatusOccasions',
  async (payload) => {
    const response = await api.updateStatusOccasions(
      payload.id,
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);
const setOccasions = createAsyncThunk(
  'giftCard/storeOccasions',
  async (payload) => {
    const response = await api.setOccasions(
      payload.gift_card_config_id,
      payload
    );
    return response;
  }
);

const getGiftCardImages = createAsyncThunk(
  'giftCard/getGiftCardImages',
  async (giftCardConfigId) => {
    return api.getGiftCardImages(giftCardConfigId);
  }
);

const resetGiftCardConfig = createAsyncThunk(
  'giftCard/resetGiftCardConfig',
  async () => {
    return true;
  }
);

const issuePhysicalGiftCard = createAsyncThunk(
  'giftCard/issuePhysicalGiftCard',
  async (payload) => {
    return api.issuePhysicalGiftCard(payload);
  }
);

const updateGiftCardTermDescription = createAsyncThunk(
  'giftCard/updateGiftCardTermDescription',
  async (payload) => {
    const response = await api.updateGiftCardTermDescription(
      payload.id,
      payload
    );
    return response;
  }
);

const getGiftCardRedeemTransactions = createAsyncThunk(
  'membership/getGiftCardRedeemTransactions',
  async (limit) => {
    const response = await api.getGiftCardRedeemTransactions(limit);
    return response;
  }
);

const downloadGiftCardRedeemTransactionsCSV = createAsyncThunk(
  'membership/downloadGiftCardRedeemTransactionsCSV',
  async () => {
    const response = await api.getGiftCardRedeemTransactions({ limit: 1000 });
    return response;
  }
);

const deleteGiftCardImage = createAsyncThunk(
  'giftCard/deleteGiftCardImage',
  async (payload) => {
    const response = await api.deleteGiftCardImage(
      payload.giftCardConfigId,
      payload.id
    );
    return response;
  }
);
const editGiftCardImage = createAsyncThunk(
  'giftCard/updateGiftCard',
  async (payload) => {
    const response = await api.updateGiftCardImages(payload.giftCardConfigId, {
      id: payload.id,
      occasionId: payload.occasionId,
      languageId: payload.languageId,
      url: payload.url,
    });
    return response;
  }
);

const updateGiftCardOccasions = createAsyncThunk(
  'giftCard/updateGCOccasions',
  async (payload) => {
    const response = await api.updateOccasions(payload.giftCardConfigId, {
      id: payload.id,
      languageId: payload.languageId,
      name: payload.name,
    });
    return response;
  }
);
const deleteGiftCardOccasions = createAsyncThunk(
  'giftCard/deleteOccasions',
  async (payload) => {
    const response = await api.deleteOccasions(payload.id);
    return response;
  }
);
const deleteGiftCardTerm = createAsyncThunk(
  'giftCard/deleteGiftCardTerm',
  async (payload) => {
    const response = await api.deleteCardTerm(payload.id);
    return response;
  }
);
const updateUserGiftCard = createAsyncThunk(
  'giftCard/updateUserGiftCard',
  async (payload) => {
    return api.updateGiftCardUserInfo(payload);
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      imgIsLoading: false,
      error: {},
      merchant: {},
      locations: [],
      giftCardConfig: {},
      configs: [],
      languages: [],
      occasions: [],
      images: [],
      redeemTransactions: [],
      csv: [],
    },
    extraReducers: {
      [setGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        giftCardConfig: getOr({}, ['payload'], action),
      }),
      [setGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        giftCardConfig: getOr({}, ['payload'], action),
      }),
      [resetGiftCardConfig.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        giftCardConfig: {},
      }),
      [getGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [setGiftCardImage.pending]: (state) => ({
        ...state,
        imgIsLoading: true,
      }),
      [setGiftCardImage.fulfilled]: (state) => ({
        ...state,
        imgIsLoading: false,
      }),
      [setGiftCardImage.rejected]: (state, payload) => ({
        ...state,
        imgIsLoading: false,
        error: payload,
      }),
      [getGiftCardConfigList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getGiftCardConfigList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: getOr([], 'payload', action),
      }),
      [getGiftCardConfigList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getLanguages.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getLanguages.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        languages: getOr([], 'payload', action),
      }),
      [getLanguages.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOccasionsByGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOccasionsByGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        occasions: getOr([], 'payload', action),
      }),
      [getOccasionsByGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getGiftCardTermsByGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getGiftCardTermsByGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        occasions: getOr([], 'payload', action),
      }),
      [getGiftCardTermsByGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getGiftCardImages.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getGiftCardImages.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        images: getOr([], 'payload', action),
      }),
      [getGiftCardImages.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [updateStatusOccasions.pending]: (state) => ({
        ...state,
        imgIsLoading: true,
      }),
      [updateStatusOccasions.fulfilled]: (state) => ({
        ...state,
        imgIsLoading: false,
      }),
      [updateStatusOccasions.rejected]: (state, payload) => ({
        ...state,
        imgIsLoading: false,
        error: payload,
      }),
      [downloadGiftCardRedeemTransactionsCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Original Merchant': r.originalMerchant,
          Location: r.locationId,
          'Gift Card #': r.code,
          'DateTime Redeemed': moment(`${r.createdAt}Z`).format(
            'MMM Do YYYY, HH:mm'
          ),
          'Amount Redeemed': r.amount,
          'Remaining Balance': r.currentAmount,
        })),
      }),
      [getGiftCardRedeemTransactions.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getGiftCardRedeemTransactions.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        redeemTransactions: getOr([], 'payload', action),
      }),
      [getGiftCardRedeemTransactions.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectMerchantDetails: (state) => getOr({}, 'merchant', state[name]),
  selectGiftCardConfig: (state) => getOr({}, 'giftCardConfig', state[name]),
  selectGiftCardConfigList: (state) => getOr([], 'configs', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectRedeemTransactions: (state) =>
    getOr({}, 'redeemTransactions', state[name]),
  selectCsv: (state) => getOr({}, 'csv', state[name]),
};

export default {
  actions: {
    ...actions,
    setGiftCardConfig,
    getGiftCardConfig,
    resetGiftCardConfig,
    setGiftCardImage,
    getGiftCardConfigList,
    getLanguages,
    getOccasionsByGiftCardConfig,
    getGiftCardTermsByGiftCardConfig,
    setGiftCardTerms,
    updateStatusOccasions,
    updateStatusGiftCardTerms,
    updateStatusGiftCardConfig,
    setDefaultGiftCardConfig,
    setOccasions,
    getGiftCardImages,
    updateStatusGiftCardImages,
    setGiftCardImages,
    issuePhysicalGiftCard,
    updateGiftCardTermDescription,
    downloadGiftCardRedeemTransactionsCSV,
    getGiftCardRedeemTransactions,
    deleteGiftCardImage,
    editGiftCardImage,
    updateGiftCardOccasions,
    deleteGiftCardOccasions,
    deleteGiftCardTerm,
    updateUserGiftCard,
  },
  selectors,
  reducer,
  name,
};
