/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormikContext, useField } from 'formik';
import { InputAdornment, Zoom, makeStyles } from '@material-ui/core';
import { Check, Block } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { PhoneMask, ZipCodeMask, Numbers } from './InputMasks';

const useStyles = makeStyles(() => ({
  input: {
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));
const CustomInputField = ({
  name,
  autoFocus,
  startAdornment,
  customInput: CustomInput,
  InputProps,
  extraEndAdornments,
  maskType,
  helperText,
  capitalize,
  trim,
  trimLeadingZeros,
  removeArrows,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  const { dirty, handleBlur: formikHandleBlur } = useFormikContext();
  const [field, meta] = useField(name);
  const [selected, setSelected] = React.useState(false);
  const handleBlur = (e) => {
    formikHandleBlur(e);
    setSelected(false);
  };
  const handleFocus = () => {
    setSelected(true);
  };

  const handleChange = (e) => {
    const handleValue = (value) => {
      if (capitalize && value.match(/^[a-z]/)) {
        return value.toUpperCase();
      }
      if (trim) {
        return value.trim();
      }
      if (trimLeadingZeros) {
        return value.replace(/^0+/, '');
      }
      return e.currentTarget.value;
    };
    const event = {
      target: {
        name: field.name,
        value: handleValue(e.currentTarget.value),
      },
    };
    if (onChange) {
      onChange(event);
    }
    field.onChange(event);
  };
  return (
    <CustomInput
      {...rest}
      name={name}
      onBlur={handleBlur}
      onFocus={handleFocus}
      helperText={meta.touched && meta.error ? meta.error : helperText}
      InputProps={{
        ...InputProps,
        onChange: handleChange,
        ...(maskType === 'phone' && {
          onChange: handleChange,
          inputComponent: PhoneMask,
        }),
        ...(maskType === 'zipcode' && {
          onChange: handleChange,
          inputComponent: ZipCodeMask,
        }),
        ...(maskType === 'numbers' && {
          onChange: handleChange,
          inputComponent: Numbers,
        }),
        inputProps: {
          onChange,
          className: removeArrows ? classes.input : '',
        },
        autoFocus: !!autoFocus,
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: extraEndAdornments && (
          <>
            <InputAdornment position="end" style={{ minWidth: '25px' }}>
              {extraEndAdornments === true ? (
                <>
                  {!meta.error && dirty && (
                    <Zoom
                      in={meta.touched && !meta.error && dirty}
                      timeout={300}
                    >
                      <Check color={selected ? 'primary' : 'disabled'} />
                    </Zoom>
                  )}
                  {meta.error && meta.touched && (
                    <Zoom in={meta.error && meta.touched} timeout={300}>
                      <Block color="error" />
                    </Zoom>
                  )}
                </>
              ) : (
                extraEndAdornments
              )}
            </InputAdornment>
          </>
        ),
      }}
    />
  );
};

CustomInputField.defaultProps = {
  autoFocus: false,
  startAdornment: null,
  InputProps: {},
  extraEndAdornments: null,
  maskType: '',
  helperText: null,
  capitalize: false,
  trim: false,
  trimLeadingZeros: false,
  removeArrows: false,
  onChange: null,
};

CustomInputField.propTypes = {
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  startAdornment: PropTypes.node,
  customInput: PropTypes.elementType.isRequired,
  InputProps: PropTypes.objectOf(PropTypes.any),
  extraEndAdornments: PropTypes.node,
  maskType: PropTypes.string,
  helperText: PropTypes.node,
  capitalize: PropTypes.bool,
  trim: PropTypes.bool,
  removeArrows: PropTypes.bool,
  trimLeadingZeros: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomInputField;
