import {
  // applyMiddleware,
  configureStore,
  // getDefaultMiddleware,
} from '@reduxjs/toolkit';
// import logger from 'redux-logger';

import rootReducer from './reducers';

// eslint-disable-next-line no-unused-vars
const createStore = (config) => {
  return configureStore({
    reducer: rootReducer,
    // middleware: getDefaultMiddleware().concat(logger),
  });
};

export default createStore;
