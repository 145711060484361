/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';

const name = 'ticket';

const getTicketConfig = createAsyncThunk(
  'ticket/getTicketConfig',
  async (payload) => {
    const response = await api.getTicketConfig(payload);
    return response;
  }
);

const setTicketConfig = createAsyncThunk(
  'ticket/setTicketConfig',
  async (payload) => {
    const response = await api.setTicketConfig(payload);
    return response;
  }
);

const getTicketVenues = createAsyncThunk(
  'ticket/getTicketVenues',
  async (params) => {
    const response = await api.getTicketVenues(params);
    return response;
  }
);
const getTicketTypes = createAsyncThunk(
  'ticket/getTicketTypes',
  async (params) => {
    const response = await api.getTicketTypes(params);
    return response;
  }
);
const getTicketEvents = createAsyncThunk(
  'ticket/getTicketEvents',
  async (params) => {
    const response = await api.getTicketEvents(params);
    return response;
  }
);
const getTickets = createAsyncThunk('ticket/getTickets', async (params) => {
  const response = await api.getTickets(params);
  return response;
});
const getOrders = createAsyncThunk('ticket/getOrders', async (params) => {
  const response = await api.getOrders(params);
  return response;
});
const getOrderItems = createAsyncThunk(
  'ticket/getOrderItems',
  async (params) => {
    const response = await api.getOrderItems(params);
    return response;
  }
);
const downloadTicketsCSV = createAsyncThunk(
  'ticket/downloadTicketsCSV',
  async () => {
    const response = await api.getTickets({ limit: 1000 });
    return response;
  }
);
const downloadOrdersCSV = createAsyncThunk(
  'ticket/downloadOrdersCSV',
  async () => {
    const response = await api.getOrders({ limit: 1000 });
    return response;
  }
);
const getTicketPricing = createAsyncThunk(
  'ticket/getTicketPricing',
  async (params) => {
    const response = await api.getTicketPricing(params);
    return response;
  }
);
const updateStatusTicketConfig = createAsyncThunk(
  'ticket/updateStatusTicketConfig',
  async (payload) => {
    const response = await api.updateStatusTicketConfig(payload.id, payload);
    return response;
  }
);

const setDefaultTicketConfig = createAsyncThunk(
  'ticket/setDefaultTicketConfig',
  async (id) => {
    const response = await api.setDefaultTicketConfig(id);
    return response;
  }
);

const resetTicketConfig = createAsyncThunk(
  'ticket/resetTicketConfig',
  async () => {
    return true;
  }
);

const getEventsDiscountsList = createAsyncThunk(
  'tickets/events-discount',
  async (payload) => {
    return api.getEventsDiscountsList(payload);
  }
);

const setEventsDiscounts = createAsyncThunk(
  'tickets/event-discount',
  async (payload) => {
    return api.setEventsDiscounts(payload);
  }
);

const editEventsDiscounts = createAsyncThunk(
  'tickets/event-discount',
  async (payload) => {
    return api.editEventsDiscounts(payload);
  }
);

const setTicketEvent = createAsyncThunk('tickets/events', async (payload) => {
  return api.postTicketEvent(payload);
});

const setCurrentTicketEvent = createAsyncThunk(
  'tickets/events',
  async (payload) => {
    return payload;
  }
);

const editTicket = createAsyncThunk('tickets/editTicket', async (payload) => {
  return api.editTicket(payload.id, payload);
});
const deleteTicketEvent = createAsyncThunk(
  'tickets/deleteTicketEvent',
  async (id) => {
    return api.deleteTicketEvent(id);
  }
);

const resendEmail = createAsyncThunk('membership/resendEmail', async (data) => {
  const response = await api.resendEmail(data);
  return response;
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      imgIsLoading: false,
      error: {},
      ticketConfig: {},
      event: {},
      configs: {
        data: [],
        total: 0,
      },
      venues: {
        data: [],
        total: 0,
      },
      ticketTypes: {
        data: [],
        total: 0,
      },
      events: {
        data: [],
        total: 0,
      },
      discounts: [],
      tickets: {
        data: [],
        total: 0,
      },
      getOrders: {
        data: [],
        total: 0,
      },
      csv: [],
      orderItems: [],
    },
    extraReducers: {
      [setTicketConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setTicketConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        ticketConfig: getOr({}, ['payload'], action),
      }),
      [setTicketConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getTicketConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTicketConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        ticketConfig: getOr({}, ['payload'], action),
      }),
      [resetTicketConfig.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        ticketConfig: {},
      }),
      [getTicketConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getTicketVenues.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTicketVenues.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          venues: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getTicketVenues.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getTicketTypes.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTicketTypes.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          ticketTypes: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getTicketTypes.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getTicketEvents.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTicketEvents.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          events: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getTicketEvents.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getTickets.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTickets.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          tickets: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getTickets.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOrders.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrders.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          orders: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getOrders.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOrderItems.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrderItems.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          orderItems: getOr([], 'payload', action),
        };
      },
      [getOrderItems.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [downloadTicketsCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Event Date & Time': `${moment(`${r.startTime}Z`).format(
            'MMM Do YYYY, HH:mm'
          )} - ${moment(`${r.endTime}Z`).format('MMM Do YYYY, HH:mm')}`,
          'Event Name': r.eventName,
          'Order #': r.orderNumber,
          'Ticket #': r.ticketNumber,
          'First Name': r.firstName,
          'Last Name': r.lastName,
          Email: r.email,
          Status: r.status === 0 ? 'Active' : r.status === 1 ? 'Pending' : '',
          'Check-In DateTime':
            r.checkInAt &&
            moment(`${r.checkInAt}Z`).format('MMM Do YYYY, HH:mm'),
        })),
      }),
      [downloadOrdersCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Event Date & Time': `${moment(`${r.startTime}Z`).format(
            'MMM Do YYYY, HH:mm'
          )} - ${moment(`${r.endTime}Z`).format('MMM Do YYYY, HH:mm')}`,
          'Event Name': r.eventName,
          'Order #': r.orderNumber,
          'Order Date':
            r.createdAt &&
            moment(`${r.createdAt}Z`).format('MMM Do YYYY, HH:mm'),
          Amount: r.amount,
          'First Name': r.firstName,
          'Last Name': r.lastName,
          Email: r.email,
          Status: r.status === 0 ? 'Active' : r.status === 1 ? 'Pending' : '',
        })),
      }),
      [getTicketPricing.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getTicketPricing.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          pricing: getOr(
            {
              data: [],
              total: 0,
            },
            'payload',
            action
          ),
        };
      },
      [getTicketPricing.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getEventsDiscountsList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getEventsDiscountsList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        discounts: getOr([], 'payload', action),
      }),
      [setTicketEvent.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setTicketEvent.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        event: getOr({}, 'payload', action),
      }),
      [setCurrentTicketEvent.fulfilled]: (state, action) => ({
        ...state,
        event: getOr({}, 'payload', action),
      }),
    },
  }),
};

const selectors = {
  selectMerchantDetails: (state) => getOr({}, 'merchant', state[name]),
  selectTicketConfig: (state) => getOr({}, 'ticketConfig', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectTicketVenues: (state) => getOr(false, 'venues', state[name]),
  selectTicketTypes: (state) => getOr(false, 'ticketTypes', state[name]),
  selectTicketEvents: (state) => getOr(false, 'events', state[name]),
  selectTickets: (state) => getOr(false, 'tickets', state[name]),
  selectOrders: (state) => getOr(false, 'orders', state[name]),
  selectOrderItems: (state) => getOr(false, 'orderItems', state[name]),
  selectTicketPricing: (state) => getOr(false, 'pricing', state[name]),
  selectActiveTicketEvent: (state) =>
    getOr([], 'events.data', state[name])?.find((e) => e.status === 'Draft') ||
    {},
  selectEventsDiscountsList: (state) => getOr({}, 'discounts', state[name]),
  selectTicketEvent: (state) => getOr(false, 'event', state[name]),
  selectCsv: (state) => getOr({}, 'csv', state[name]),
};

export default {
  actions: {
    ...actions,
    setTicketConfig,
    getTicketConfig,
    resetTicketConfig,
    updateStatusTicketConfig,
    setDefaultTicketConfig,
    getTicketVenues,
    getTicketTypes,
    getTicketEvents,
    getTicketPricing,
    getEventsDiscountsList,
    setEventsDiscounts,
    editEventsDiscounts,
    setTicketEvent,
    getTickets,
    getOrders,
    downloadTicketsCSV,
    downloadOrdersCSV,
    getOrderItems,
    setCurrentTicketEvent,
    editTicket,
    resendEmail,
    deleteTicketEvent,
  },
  selectors,
  reducer,
  name,
};
