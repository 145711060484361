/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'auth';
const okRole = 'Merchant';
let refreshTokenTimeout;

const startRefreshTokenTimer = createAsyncThunk(
  'auth/startTokenRefresh',
  async (payload, { dispatch }) => {
    const token = getOr('', 'jwtToken', payload);
    console.log(token, payload);
    if (token) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(token && atob(token.split('.')[1]));
      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      refreshTokenTimeout = setTimeout(
        // eslint-disable-next-line no-use-before-define
        () => dispatch(refreshToken()),
        timeout
      );
    }
  }
);

const stopRefreshTokenTimer = createAsyncThunk(
  'auth/stopTokenRefresh',
  async () => {
    await clearTimeout(refreshTokenTimeout);
  }
);

const login = createAsyncThunk('auth/login', async (payload, { dispatch }) => {
  const response = await api.login(payload).then((res) => {
    dispatch(startRefreshTokenTimer(res));
    return res;
  });
  return response;
});

const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (payload, { dispatch }) => {
    const response = await api.refreshToken().then((res) => {
      dispatch(startRefreshTokenTimer(res));
      return res;
    });
    return response;
  }
);
const logout = createAsyncThunk(
  'auth/logout',
  async (payload, { dispatch }) => {
    const response = await api.logout();
    dispatch(stopRefreshTokenTimer());
    return response;
  }
);

const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (payload) => {
    const response = await api.forgotPassword(payload);
    return response;
  }
);

const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload) => {
    const response = await api.resetPassword(payload);
    return response;
  }
);
const register = createAsyncThunk('auth/register', async (payload) => {
  const response = await api.register(payload);
  return response;
});

const verifyEmail = createAsyncThunk('auth/register', async (payload) => {
  const response = await api.verifyEmail(payload);
  return response;
});

const resendRegistrationCode = createAsyncThunk(
  'auth/register',
  async (payload) => {
    const response = await api.resendCode(payload);
    return response;
  }
);

const posAuth = createAsyncThunk('auth/posAuth', async () => {
  const response = await api.posAuth();
  return response;
});

const loginByCode = createAsyncThunk(
  'auth/loginByCode',
  async (payload, { dispatch }) => {
    const response = await api.loginByCode(payload).then((res) => {
      dispatch(startRefreshTokenTimer(res));
      return res;
    });
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      user: {},
      isLoggedIn: false,
      oauthUrl: '',
      cloverEuOauthLink: '',
      cloverOauthLink: '',
    },
    reducers: {
      setIsLoggedIn: (state, action) => ({
        ...state,
        isLoggedIn: action.payload,
      }),
      setUser: (state, action) => ({
        ...state,
        user: action.payload,
      }),
    },
    extraReducers: {
      [login.pending]: (state) => ({
        ...state,
        isLoading: true,
        user: {},
      }),
      [login.fulfilled]: (state, action) => {
        const { role } = getOr('', ['payload'], action);
        return {
          ...state,
          error: {},
          isLoading: false,
          user: getOr({}, 'payload', action),
          isLoggedIn:
            !!getOr(false, ['payload', 'jwtToken'], action) && role === okRole,
        };
      },
      [login.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      [loginByCode.pending]: (state) => ({
        ...state,
        isLoading: true,
        user: {},
      }),
      [loginByCode.fulfilled]: (state, action) => {
        return {
          ...state,
          error: {},
          isLoading: false,
          user: getOr({}, 'payload', action),
          isLoggedIn: !!getOr(false, ['payload', 'jwtToken'], action),
        };
      },
      [loginByCode.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // logout
      [logout.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [logout.fulfilled]: (state) => ({
        ...state,
        error: {},
        isLoading: false,
        isLoggedIn: false,
        user: {},
      }),
      [logout.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // Token Refresh
      [refreshToken.pending]: (state) => ({
        ...state,
        isLoading: true,
        user: {},
      }),
      [refreshToken.fulfilled]: (state, action) => {
        const { role } = getOr('', ['payload'], action);
        return {
          ...state,
          error: {},
          isLoading: false,
          isLoggedIn:
            !!getOr(false, ['payload', 'jwtToken'], action) && role === okRole,
          user: getOr({}, 'payload', action),
        };
      },
      [refreshToken.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // Resend Password Reset Code
      [forgotPassword.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [forgotPassword.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [forgotPassword.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      // Resend Registration Code
      [resendRegistrationCode.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [resendRegistrationCode.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [resendRegistrationCode.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [posAuth.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [posAuth.fulfilled]: (state, action) => ({
        ...state,
        oauthLink: getOr('', ['payload', 'oauthUrl'], action),
        cloverOauthLink: getOr('', ['payload', 'cloverOauthUrl'], action),
        cloverEuOauthLink: getOr('', ['payload', 'cloverEuOauthUrl'], action),
        isLoading: false,
      }),
      [posAuth.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        oauthLink: '',
        cloverOauthLink: '',
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectUser: (state) => getOr({}, 'user', state[name]),
  selectIsLoggedIn: (state) => getOr(false, 'isLoggedIn', state[name]),
  selectToken: (state) => getOr('', ['data', 'jwtToken'], state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectOauthLink: (state) => getOr('', 'oauthLink', state[name]),
  selectCloverOauthLink: (state) => getOr('', 'cloverOauthLink', state[name]),
  selectCloverEuOauthLink: (state) =>
    getOr('', 'cloverEuOauthLink', state[name]),
};

export default {
  actions: {
    ...actions,
    login,
    logout,
    refreshToken,
    forgotPassword,
    resetPassword,
    register,
    verifyEmail,
    resendRegistrationCode,
    stopRefreshTokenTimer,
    startRefreshTokenTimer,
    posAuth,
    loginByCode,
  },
  selectors,
  reducer,
  name,
};
