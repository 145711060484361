/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'stats';

const getMerchantStats = createAsyncThunk(
  'stats/getMerchantStats',
  async () => {
    const response = await api.getMerchantStats();
    return response;
  }
);
const getChartStats = createAsyncThunk(
  'stats/getChartStats',
  async (params) => {
    const response = await api.getChartStats(params);
    return response;
  }
);
const getMerchantStoreCreditStats = createAsyncThunk(
  'stats/getMerchantStoreCreditStats',
  async () => {
    const response = await api.getMerchantStoreCreditStats();
    return response;
  }
);

const getMerchantMembershipStats = createAsyncThunk(
  'stats/getMerchantMembershipStats',
  async () => {
    const response = await api.getMerchantMembershipStats();
    return response;
  }
);

const getMerchantLoyaltyStats = createAsyncThunk(
  'stats/getMerchantLoyaltyStats',
  async () => {
    const response = await api.getMerchantLoyaltyStats();
    return response;
  }
);

const getMerchantCouponStats = createAsyncThunk(
  'stats/getMerchantCouponStats',
  async () => {
    const response = await api.getMerchantCouponStats();
    return response;
  }
);

const getMerchantTicketStats = createAsyncThunk(
  'stats/getMerchantTicketStats',
  async () => {
    const response = await api.getMerchantTicketStats();
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      stats: {},
    },
    extraReducers: {
      [getMerchantStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
      [getMerchantStoreCreditStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantStoreCreditStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantStoreCreditStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
      [getMerchantMembershipStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantMembershipStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantMembershipStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
      [getMerchantLoyaltyStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantLoyaltyStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantLoyaltyStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
      [getMerchantCouponStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantCouponStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantCouponStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
      [getMerchantTicketStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantTicketStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getMerchantTicketStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
        stats: {},
      }),
    },
  }),
};

const selectors = {
  selectMerchantStats: (state) => getOr({}, 'stats', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getMerchantStats,
    getChartStats,
    getMerchantStoreCreditStats,
    getMerchantMembershipStats,
    getMerchantLoyaltyStats,
    getMerchantCouponStats,
    getMerchantTicketStats,
  },
  selectors,
  reducer,
  name,
};
