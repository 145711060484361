/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'stats';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getMerchantTransactions: (params) =>
    api.get(`${path}/merchanttransactions?${serialize(params)}`),
  getMerchantStoreCreditTransactions: (params) =>
    api.get(`${path}/store-credit-transactions?${serialize(params)}`),
  getMembershipTransactions: (params) =>
    api.get(`${path}/membership-transactions?${serialize(params)}`),
  resendEmail: (id) => api.post(`checkout/resendgiftemail/${id}`),
};
