import React from 'react';
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 100,
  },
  label: {
    fontWeight: 500,
    marginRight: 15,
  },
}));

const SelectCustom = withStyles({
  root: {
    marginTop: -8,
    fontWeight: 500,
  },
})(Select);

const CustomSelect = ({
  label,
  onChange,
  value,
  helperText,
  options,
  name,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div style={{ display: 'flex', marginTop: 5 }}>
      {label && (
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      )}
      <FormControl className={classes.formControl} {...rest}>
        <SelectCustom
          name={name}
          value={value}
          onChange={handleChange}
          {...rest}
          // className={classes.label}
        >
          {options.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </SelectCustom>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

CustomSelect.defaultProps = {
  helperText: '',
  label: '',
  value: '',
  onChange: null,
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomSelect;
