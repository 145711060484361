/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';
import i18n from '../../i18n';

const name = 'storeCredit';

const getStoreCreditConfig = createAsyncThunk(
  'storeCredit/getStoreCreditConfig',
  async () => {
    const response = await api.getStoreCreditConfig();
    return response;
  }
);

const setStoreCreditConfig = createAsyncThunk(
  'storeCredit/setStoreCreditConfig',
  async (payload) => {
    const response = await api.setStoreCreditConfig(payload);
    return response;
  }
);

const getStoreCreditPlans = createAsyncThunk(
  'storeCredit/getStoreCreditPlans',
  async () => {
    const response = await api.getStoreCreditPlans();
    return response;
  }
);
const getStoreCreditsList = createAsyncThunk(
  'storeCredit/getStoreCreditsList',
  async (params) => {
    const response = await api.getStoreCreditsList(params);
    return response;
  }
);
const getStoreCreditsRedemptionsList = createAsyncThunk(
  'storeCredit/getStoreCreditsRedemptionsList',
  async (params) => {
    const response = await api.getStoreCreditsRedemptionsList(params);
    return response;
  }
);
const clearStoreCreditsBalance = createAsyncThunk(
  'storeCredit/clearStoreCreditsBalance',
  async ({ businessId, ...params }, { dispatch }) => {
    const response = await api.clearStoreCreditsBalance({ businessId });
    dispatch(getStoreCreditsList(params));
    return response;
  }
);
const syncStoreCredits = createAsyncThunk(
  'storeCredit/syncStoreCredits',
  async ({ businessId, ...params }, { dispatch }) => {
    const response = await api.syncStoreCredits({ businessId });
    if (response) {
      dispatch(getStoreCreditsList(params));
    }
    return response;
  }
);

const importStoreCredits = createAsyncThunk(
  'storeCredit/importStoreCredits',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.importStoreCredits(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const downloadCSV = createAsyncThunk('storeCredit/downloadCSV', async () => {
  const response = await api.getStoreCreditsList({ limit: 1000 });
  return response;
});
const defaultStoreCredit = {
  data: [],
  total: 0,
  totalAmount: 0,
  currency: 'USD',
};
const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      configs: {},
      isLoading: false,
      imgIsLoading: false,
      plans: [],
      storeCredits: defaultStoreCredit,
      redemptions: {
        isLoading: false,
        total: 0,
        data: [],
      },
      total: 0,
      csv: [],
      error: null,
      syncing: false,
    },
    extraReducers: {
      [getStoreCreditConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getStoreCreditConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: getOr(null, 'payload', action),
      }),
      [getStoreCreditConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getStoreCreditPlans.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getStoreCreditPlans.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        plans: getOr([], 'payload', action),
      }),
      [getStoreCreditPlans.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getStoreCreditsList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getStoreCreditsList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        storeCredits: getOr(defaultStoreCredit, 'payload', action),
      }),
      [getStoreCreditsList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getStoreCreditsRedemptionsList.pending]: (state) => ({
        ...state,
        redemptions: { ...state.redemptions, isLoading: true },
      }),
      [getStoreCreditsRedemptionsList.fulfilled]: (state, action) => ({
        ...state,
        redemptions: { ...action.payload, isLoading: false },
      }),
      [getStoreCreditsRedemptionsList.rejected]: (state, payload) => ({
        ...state,
        redemptions: { ...state.redemptions, isLoading: true },
        error: payload,
      }),
      [downloadCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'First Name': r.firstName,
          'Last Name': r.lastName,
          'Email Address': r.email,
          'Store Credit #': r.code,
          'Issued Date': moment(`${r.createdAt}Z`).format('MMM Do YYYY'),
          'Store Credit Amount': i18n.t('cardValue', {
            amount: r.amount,
            formatParams: {
              amount: { currency: r.currency },
            },
          }),
          'Current Balance': i18n.t('cardValue', {
            amount: r.balance,
            formatParams: {
              amount: { currency: r.currency },
            },
          }),
        })),
      }),
      [clearStoreCreditsBalance.pending]: (state) => ({
        ...state,
        syncing: true,
      }),
      [clearStoreCreditsBalance.fulfilled]: (state) => ({
        ...state,
        syncing: false,
      }),
      [clearStoreCreditsBalance.rejected]: (state, payload) => ({
        ...state,
        syncing: false,
        error: payload,
      }),
      [syncStoreCredits.pending]: (state) => ({
        ...state,
        syncing: true,
      }),
      [syncStoreCredits.fulfilled]: (state) => ({
        ...state,
        syncing: false,
      }),
      [syncStoreCredits.rejected]: (state, payload) => ({
        ...state,
        syncing: false,
        error: payload,
      }),
      [importStoreCredits.pending]: (state) => ({
        ...state,
        syncing: true,
      }),
      [importStoreCredits.fulfilled]: (state) => ({
        ...state,
        syncing: false,
      }),
      [importStoreCredits.rejected]: (state, payload) => ({
        ...state,
        syncing: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectStoreCreditConfig: (state) =>
    getOr({ plans: [{}] }, 'configs', state[name]),
  selectIsLoading: (state) => getOr({}, 'isLoading', state[name]),
  selectSyncing: (state) => getOr({}, 'syncing', state[name]),
  selectPlans: (state) => getOr([], 'plans', state[name]),
  selectImgIsLoading: (state) => getOr(false, 'imgIsLoading', state[name]),
  selectStoreCreditsList: (state) =>
    getOr(defaultStoreCredit, 'storeCredits', state[name]),
  selectStoreCreditsRedemptionsList: (state) =>
    getOr({ data: [], total: 0 }, 'redemptions', state[name]),
  selectStoreCreditsCsv: (state) => getOr({}, 'csv', state[name]),
};

export default {
  actions: {
    ...actions,
    getStoreCreditConfig,
    setStoreCreditConfig,
    getStoreCreditPlans,
    getStoreCreditsList,
    getStoreCreditsRedemptionsList,
    downloadCSV,
    clearStoreCreditsBalance,
    syncStoreCredits,
    importStoreCredits,
  },
  selectors,
  reducer,
  name,
};
