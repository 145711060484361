/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'merchant';

const getMerchantDetails = createAsyncThunk(
  'merchant/getMerchantDetails',
  async () => {
    const response = await api.getMerchantDetails();
    return response;
  }
);

const createApiToken = createAsyncThunk('merchant/createApiToken', async () => {
  const response = await api.createApiToken();
  return response;
});
const getApiToken = createAsyncThunk('merchant/getApiToken', async () => {
  const response = await api.getApiToken();
  return response;
});
const getMerchantLocations = createAsyncThunk(
  'merchant/getMerchantLocations',
  async () => {
    const response = await api.getMerchantLocations();
    return response;
  }
);

const setMerchantLocation = createAsyncThunk(
  'merchant/setMerchantLocation',
  async (payload) => {
    const response = await api.setMerchantLocation(payload);
    return response;
  }
);

const getDefaultGiftCardConfig = createAsyncThunk(
  'merchant/getGiftCardConfig',
  async (payload) => {
    const response = await api.getGiftCardConfig(payload);
    return response;
  }
);

const getEnterprisesList = createAsyncThunk(
  'merchant/getEnterprisesList',
  async (payload) => {
    const response = await api.getEnterprisesList(payload);
    return response;
  }
);
const getCategoriesList = createAsyncThunk(
  'merchant/getCategoriesList',
  async (payload) => {
    const response = await api.getCategoriesList(payload);
    return response;
  }
);

const setGiftCardConfig = createAsyncThunk(
  'merchant/setGiftCardConfig',
  async (payload) => {
    const response = await api.setGiftCardConfig(payload);
    return response;
  }
);

const setGiftCardImage = createAsyncThunk(
  'merchant/setGiftCardImage',
  async (payload) => {
    const response = await api.setGiftCardImage(payload);

    return response;
  }
);
const setAccountInfo = createAsyncThunk(
  'merchant/setAccountInfo',
  async (payload) => {
    const response = await api.setAccountInfo(payload);

    return response;
  }
);
const setContactInfo = createAsyncThunk(
  'merchant/setContactInfo',
  async (payload) => {
    const response = await api.setContactInfo(payload);

    return response;
  }
);

const createEnterprise = createAsyncThunk(
  'merchant/createEnterprise',
  async (payload) => {
    return api.createEnterprise(payload);
  }
);

const resendEnterpriseEmail = createAsyncThunk(
  'merchant/resendEnterpriseEmail',
  async (payload) => {
    return api.resendEnterpriseEmail(payload);
  }
);
const getMerchantBulkDiscount = createAsyncThunk(
  'merchant/getMerchantBulkDiscount',
  async (payload) => {
    return api.getMerchantBulkDiscount(payload);
  }
);
const createBulkDiscount = createAsyncThunk(
  'merchant/createBulkDiscount',
  async (payload) => {
    return api.createBulkDiscount(payload);
  }
);
const updateBulkDiscountStatus = createAsyncThunk(
  'merchant/updateBulkDiscountStatus',
  async (payload) => {
    return api.updateBulkDiscountStatus(payload);
  }
);

const updateRegister = createAsyncThunk(
  'merchant/updateRegister',
  async (payload) => {
    return api.updateRegister(payload);
  }
);

const getMerchantBrandColorConfigs = createAsyncThunk(
  'merchant/getMerchantBrandColorConfigs',
  async () => {
    const response = await api.getMerchantBrandColorConfigs();
    return response;
  }
);

// const updateAddress = createAsyncThunk(
//   'merchant/updateAddress',
//   async (payload, { getState }) => {
//     const user = getOr(null, ['auth', 'user'], getState());
//     const response = await api.updateAddress(payload, user);
//     return response;
//   }
// );

// const updateBankDetails = createAsyncThunk(
//   'merchant/updateBankDetails',
//   async (payload, { getState }) => {
//     const user = getOr(null, ['auth', 'user'], getState());
//     const response = await api.updateBankDetails(payload, user);
//     return response;
//   }
// );

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      imgIsLoading: false,
      error: {},
      merchant: {},
      locations: [],
      creatives: {},
      enterprises: {
        count: 0,
        data: [],
      },
      brandColors: {},
    },
    extraReducers: {
      [getMerchantDetails.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantDetails.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        merchant: getOr({}, 'payload', action),
      }),
      [getMerchantDetails.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMerchantLocations.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantLocations.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        locations: getOr([], 'payload', action),
      }),
      [getMerchantLocations.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [setMerchantLocation.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setMerchantLocation.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        merchant: {
          ...state.merchant,
          locationId: getOr('', ['payload', 'locationId'], action),
        },
        locations: [],
      }),
      [setMerchantLocation.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [setGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        merchant: {
          ...state.merchant,
          creatives: getOr({}, ['payload'], action),
        },
      }),
      [setGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getDefaultGiftCardConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getDefaultGiftCardConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        merchant: {
          ...state.merchant,
          creatives: getOr({}, ['payload'], action),
        },
      }),
      [getDefaultGiftCardConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [setGiftCardImage.pending]: (state) => ({
        ...state,
        imgIsLoading: true,
      }),
      [setGiftCardImage.fulfilled]: (state) => ({
        ...state,
        imgIsLoading: false,
      }),
      [setGiftCardImage.rejected]: (state, payload) => ({
        ...state,
        imgIsLoading: false,
        error: payload,
      }),
      [getEnterprisesList.pending]: (state) => ({
        ...state,
        loading: true,
      }),
      [getEnterprisesList.fulfilled]: (state, action) => ({
        ...state,
        loading: false,
        enterprises: action.payload,
      }),
      [getEnterprisesList.rejected]: (state, action) => ({
        ...state,
        loading: false,
        error: action,
      }),
      [getMerchantBrandColorConfigs.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantBrandColorConfigs.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        brandColors: getOr([], 'payload', action),
      }),
      [getMerchantBrandColorConfigs.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectMerchantDetails: (state) => getOr({}, 'merchant', state[name]),
  selectCurrency: (state) =>
    getOr('USD', ['merchant', 'currency'], state[name]) || 'USD',
  selectMerchantCreatives: (state) =>
    getOr({}, ['merchant', 'creatives'], state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectImgIsLoading: (state) => getOr(false, 'imgIsLoading', state[name]),
  selectLocations: (state) => getOr(false, 'locations', state[name]),
  selectEnterprises: (state) => getOr(false, 'enterprises', state[name]),
  isShopify: (state) =>
    getOr('', ['merchant', 'partner'], state[name]) === 'Shopify',
  selectBrandColors: (state) => getOr({}, 'brandColors', state[name]),
};

export default {
  actions: {
    ...actions,
    getMerchantDetails,
    getMerchantLocations,
    setMerchantLocation,
    setGiftCardConfig,
    getDefaultGiftCardConfig,
    createApiToken,
    getApiToken,
    setGiftCardImage,
    setAccountInfo,
    setContactInfo,
    getEnterprisesList,
    getCategoriesList,
    createEnterprise,
    resendEnterpriseEmail,
    getMerchantBulkDiscount,
    createBulkDiscount,
    updateBulkDiscountStatus,
    updateRegister,
    getMerchantBrandColorConfigs,
  },
  selectors,
  reducer,
  name,
};
