import api from '../../utils/apiFetch';

const path = 'stats';

export default {
  getMerchantStats: () => api.get(`${path}/merchanttotals`),
  getChartStats: (params) => api.get(`${path}/chart?${api.serialize(params)}`),
  getMerchantStoreCreditStats: () => api.get(`${path}/store-credit-totals`),
  getMerchantMembershipStats: () => api.get(`${path}/membership-totals`),
  getMerchantLoyaltyStats: () => api.get(`${path}/loyalty-totals`),
  getMerchantCouponStats: () => api.get(`${path}/coupon-totals`),
  getMerchantTicketStats: () => api.get(`${path}/ticket-totals`),
};
