import api from '../../utils/apiFetch';

const path = 'merchants';

export default {
  getMerchantDetails: () => api.get(`${path}/info`),
  getMerchantLocations: () => api.get(`${path}/locations`),
  setMerchantLocation: (payload) => api.post(`${path}/locations`, payload),
  setGiftCardConfig: (payload) => api.post(`${path}/creative`, payload),
  getGiftCardConfig: () => api.get(`${path}/creative`),
  setGiftCardImage: (payload) => api.file(`${path}/creativeimage`, payload),
  setAccountInfo: (payload) => api.post(`${path}/account`, payload),
  setContactInfo: (payload) => api.post(`${path}/contact`, payload),
  createEnterprise: (payload) => api.post(`${path}/enterprise`, payload),
  createBulkDiscount: (payload) => api.post(`gift-cards/discounts`, payload),
  updateRegister: (payload) => api.post(`${path}/update`, payload),
  createApiToken: (payload) => api.post(`auth/api-token`, payload),
  getApiToken: (payload) => api.get(`auth/api-token`, payload),
  updateBulkDiscountStatus: (payload) =>
    api.put(`gift-cards/discounts/${payload.id}`, payload),
  getMerchantBulkDiscount: (payload) =>
    api.get(`gift-cards/discounts?${api.serialize(payload)}`),
  resendEnterpriseEmail: (payload) =>
    api.post(`${path}/enterprise/resend`, payload),
  getEnterprisesList: (payload) =>
    api.get(`${path}/enterprise?${api.serialize(payload)}`),
  getCategoriesList: (payload) =>
    api.get(`${path}/categories?${api.serialize(payload)}`),
  getMerchantBrandColorConfigs: () => api.get(`${path}/brand-colors`),
  createMerchantBrandColorConfigs: (payload) =>
    api.post(`${path}/brand-colors`, payload),
  updateMerchantBrandColorConfigs: (payload) =>
    api.put(`${path}/brand-colors`, payload),
};
