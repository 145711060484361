/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'memberships';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getMerchantMembershipConfig: () => api.get(`${path}/configs`),
  setMerchantMembershipConfig: (payload) =>
    api.post(`${path}/configs`, payload),
  setSponsorImage: (payload) =>
    api.file(`${path}/upload-sponsor-image`, payload),
  setAutomaticStoreCredit: (payload) =>
    api.post(`${path}/store-credits`, payload),
  createMembershipDiscount: (payload) => api.post(`${path}/discounts`, payload),
  createMembershipPlan: (payload) => api.post(`${path}/plans`, payload),
  deleteMembershipDiscount: (id) => api.post(`${path}/discounts/${id}`),
  updateAutomaticStoreCredit: (payload) =>
    api.put(`${path}/store-credits`, payload),
  updateMembership: (payload) => api.put(`${path}/member`, payload),
  getMembershipPlans: () => api.get(`${path}/plans`),
  getCheckInList: (params) =>
    api.get(`${path}/check-in/list?${serialize(params)}`),
  getMembershipList: (params) => api.get(`${path}/list?${serialize(params)}`),
  getSharedMembershipConfigs: (params) =>
    api.get(`${path}/secondary?${serialize(params)}`),
  getMembershipPlanStoreCreditList: () => api.get(`${path}/store-credits`),
  getMembershipDiscountsList: () => api.get(`${path}/discounts`),
  resendEmail: (payload) =>
    api.post(`${path}/resend/${payload.id}`, { type: payload.type }),
  cancelMembership: (id) => api.post(`${path}/cancel/by-merchant/${id}`),
  deactivateMembership: (id) => api.post(`${path}/deactivate/${id}`),
  setMembershipCoupon: (payload) =>
    api.post(`${path}/membership-coupons`, payload),
  createMembershipLoyalty: (payload) =>
    api.post(`${path}/membership-loyalties`, payload),
  getMemberShipCouponPlanList: (params) =>
    api.get(`${path}/membership-coupon-list?${serialize(params)}`),
  getMemberShipLoyaltyPlanList: (params) =>
    api.get(`${path}/membership-loyalty-plans?${serialize(params)}`),
  setMembershipCouponEnable: (id, enable) =>
    api.get(`${path}/membership-coupon-enable/${id}/${enable}`),
  setMembershipLoyaltyEnable: (id, enable) =>
    api.get(`${path}/membership-loyalty-enable/${id}/${enable}`),
  deleteMembershipCouponPlan: (id) =>
    api.get(`${path}/membership-coupon-delete/${id}`),
  updateMembershipExpirationDate: (payload) =>
    api.post(`${path}/expiration-date`, payload),
  updateMembershipPlan: (payload) =>
    api.post(`${path}/membership-plan`, payload),
  reactiveMembership: (payload) => api.post(`${path}/reactive`, payload),
  getMembershipFreeItemConfigs: () => api.get(`${path}/free-item-configs`),
  createMembershipFreeItemConfig: (payload) =>
    api.post(`${path}/create-free-item-configs`, payload),
  getMembershipPerks: (params) => api.get(`${path}/perks?${serialize(params)}`),
  updateMembershipPerks: (payload) =>
    api.post(`${path}/free-item-configs`, payload),
  updatePlan: (payload) => api.put(`${path}/plan`, payload),
  changePlanStatus: (payload) =>
    api.post(`${path}/plan/${payload.id}/status/${payload.status}`),
  updateMembershipTimePause: (payload) =>
    api.post(`${path}/update-time-pause`, payload),
  setMembershipResume: (id) => api.get(`${path}/resume/${id}`),
  getTransactionList: (id) => api.get(`${path}/${id}/transactions`),
  createMemberShip: (payload) => api.post(`${path}/add-membership`, payload),
  addSecondaryMemberShip: (payload) =>
    api.post(`${path}/add-secondary-membership`, payload),
  validateMembershipCode: (payload) =>
    api.post(`${path}/validate-membership-code`, payload),
};
