/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

let preloadData = [];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
    padding: 8,
    whiteSpace: 'pre-line',
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  // table: {
  //   minWidth: 700,
  // },
});

const TransactionTable = ({
  cols,
  data,
  rowKeys,
  mods,
  isLoading,
  noDataMsg,
  isMinWidth,
  size,
  pagination,
  searchBox,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  React.useEffect(() => {
    preloadData = cols.map((col, i) => {
      return { id: i, [col]: null };
    });
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Box mb={2}>{searchBox?.()} </Box>
        </Grid>
        <Grid item style={{ alignItems: 'flex-end' }}>
          <Box mb={2}>
            {pagination && (
              <TablePagination
                style={{ marginRight: -10, marginBottom: -16 }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={pagination.total}
                rowsPerPage={pagination.pageSize}
                page={pagination.page - 1}
                labelRowsPerPage={t('rowsPerPage')}
                labelDisplayedRows={(p) =>
                  `${p.from} - ${p.to} ${t('of')} ${p.count}`
                }
                onPageChange={pagination.handleChangePage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                SelectProps={{ style: { marginLeft: 10, marginRight: 10 } }}
                nextIconButtonProps={{ style: { padding: '8px 4px 8px 4px' } }}
                backIconButtonProps={{ style: { padding: '8px 4px 8px 4px', marginLeft: -16 } }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          styles={{ minWidth: isMinWidth ? 700 : '' }}
          aria-label="Organization Payouts"
          size={size || 'medium'}
        >
          <TableHead>
            <TableRow>
              {cols.map((col, i) => {
                if (i === 0) {
                  return (
                    <StyledTableCell key={col}>
                      {isLoading ? (
                        <Skeleton style={{ backgroundColor: '#fff' }} />
                      ) : (
                        <b>{col}</b>
                      )}
                    </StyledTableCell>
                  );
                }
                if (typeof col === 'object') {
                  return (
                    <StyledTableCell
                      key={col}
                      align="right"
                      style={col.style || {}}
                    >
                      {isLoading ? (
                        <Skeleton style={{ backgroundColor: '#fff' }} />
                      ) : (
                        <b>{col.value}</b>
                      )}
                    </StyledTableCell>
                  );
                }
                return (
                  <StyledTableCell key={col} align="right">
                    {isLoading ? (
                      <Skeleton style={{ backgroundColor: '#fff' }} />
                    ) : (
                      <b>{col}</b>
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? preloadData.map((datum) => (
                  <StyledTableRow key={datum.id}>
                    {rowKeys.map((rowKey) => (
                      <StyledTableCell align="right" key={rowKey}>
                        {isLoading ? <Skeleton /> : datum[rowKey]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              : (data || []).map((datum) => (
                  <StyledTableRow key={datum.id}>
                    {rowKeys.map((rowKey, i) => {
                      let val = getOr('', rowKey.split('.'), datum);
                      if (mods) {
                        const withModifier = getOr(false, `${rowKey}`, mods);
                        if (withModifier) {
                          val = withModifier(val, datum);
                        }
                      }
                      if (i === 0) {
                        return (
                          <StyledTableCell key={rowKey}>
                            {isLoading ? <Skeleton /> : val}
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell align="right" key={rowKey}>
                          {isLoading ? <Skeleton /> : val}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && data.length === 0 ? (
        <Box pt={2} width="100%" align="center">
          <Typography variant="h4" align="center">
            {noDataMsg}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

TransactionTable.defaultProps = {
  isLoading: false,
  data: preloadData,
  isMinWidth: true,
  size: null,
  noDataMsg: 'Hmm... Looks like there is no data here.',
  pagination: null,
  searchBox: null,
};

TransactionTable.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  mods: PropTypes.objectOf(PropTypes.func).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isMinWidth: PropTypes.bool,
  noDataMsg: PropTypes.string,
  size: PropTypes.string,
  pagination: PropTypes.shape({
    total: PropTypes.number,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
  }),
  searchBox: PropTypes.elementType,
};

export default memo(TransactionTable);
