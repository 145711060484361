import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const InfoCard = ({
  icon,
  label,
  children,
  isLoading,
  onClick,
  clickable,
  disabled,
}) =>
  clickable ? (
    <Card style={{ minWidth: '100%' }} raised elevation={3}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={icon ? 9 : 12}>
              <Typography variant="caption" color="textSecondary">
                {isLoading ? <Skeleton width={150} /> : label}
              </Typography>
              <Typography variant="h6" color="primary">
                {isLoading ? <Skeleton width={75} /> : children}
              </Typography>
            </Grid>
            {icon && (
              <Grid item xs={3}>
                <Typography color="textSecondary" align="right">
                  {icon}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <Card style={{ minWidth: '100%' }} raised elevation={3}>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={icon ? 9 : 12}>
            <Typography variant="caption" color="textSecondary">
              {isLoading ? <Skeleton width={150} /> : label}
            </Typography>
            <Typography variant="h6" color="primary">
              {isLoading ? <Skeleton width={75} /> : children}
            </Typography>
          </Grid>
          {icon && (
            <Grid item xs={3}>
              <Typography color="textSecondary" align="right">
                {icon}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );

InfoCard.defaultProps = {
  icon: '',
  isLoading: false,
  onClick: noop,
  clickable: false,
  disabled: false,
};

InfoCard.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
};

export default InfoCard;
