/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/apiFetch';

const path = 'loyalties';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getNewLoyaltyProgram: () => api.get(`${path}/program`),
  saveLoyaltyProgram: (payload) =>
    api.put(`${path}/program/${payload.id}`, payload),
  getProgramList: (params) => api.get(`${path}/programs?${serialize(params)}`),
  getLoyaltyList: (params) => api.get(`${path}?${serialize(params)}`),
  addLoyaltyPoint: (payload) => api.post(`${path}/add-points`, payload),
  uploadLoyaltyCSV: (params) => api.file(`${path}/import`, params),
  getItems: (params) => api.get(`${path}/items?${serialize(params)}`),
  getItemsByCategory: (categories) =>
    api.post(`${path}/items`, {
      category_ids:
        categories === undefined
          ? undefined
          : Array.isArray(categories)
          ? categories
          : [categories],
    }),
  getLoyaltyTransactions: (params) =>
    api.get(`${path}/loyalty-transactions?${serialize(params)}`),
  resendEmail: (id) => api.get(`${path}/resend/${id}`),
};
