/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';
import { membershipStatuses } from '../../utils/constants';

const name = 'membership';

const getMerchantMembershipConfig = createAsyncThunk(
  'membership/getMerchantMembershipConfig',
  async () => {
    const response = await api.getMerchantMembershipConfig();
    return response;
  }
);
const getMembershipPlans = createAsyncThunk(
  'membership/getMembershipPlans',
  async () => {
    const response = await api.getMembershipPlans();
    return response;
  }
);
const getCheckInList = createAsyncThunk(
  'membership/getCheckInList',
  async (limit) => {
    const response = await api.getCheckInList(limit);
    return response;
  }
);

const getMembershipList = createAsyncThunk(
  'membership/getMembershipList',
  async (limit) => {
    const response = await api.getMembershipList(limit);
    return response;
  }
);

const getSharedMembershipConfigs = createAsyncThunk(
  'membership/getSharedMembershipConfigs',
  async (limit) => {
    const response = await api.getSharedMembershipConfigs(limit);
    return response;
  }
);

const getMembershipPlanStoreCreditList = createAsyncThunk(
  'membership/getMembershipPlanStoreCreditList',
  async () => {
    return api.getMembershipPlanStoreCreditList();
  }
);

const getMembershipDiscountsList = createAsyncThunk(
  'membership/getMembershipDiscountsList',
  async () => {
    return api.getMembershipDiscountsList();
  }
);

const downloadCheckInCSV = createAsyncThunk(
  'membership/downloadCheckInCSV',
  async () => {
    const response = await api.getCheckInList({ limit: 1000 });
    return response;
  }
);

const downloadMembershipCSV = createAsyncThunk(
  'membership/downloadMembershipCSV',
  async () => {
    const response = await api.getMembershipList({ limit: 1000 });
    return response;
  }
);

const setMerchantMembershipConfig = createAsyncThunk(
  'membership/setMerchantMembershipConfig',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.setMerchantMembershipConfig(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setSponsorImage = createAsyncThunk(
  'merchant/setSponsorImage',
  async (payload) => {
    const response = await api.setSponsorImage(payload);

    return response;
  }
);

const setAutomaticStoreCredit = createAsyncThunk(
  'membership/setAutomaticStoreCredit',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.setAutomaticStoreCredit(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createMembershipDiscount = createAsyncThunk(
  'membership/createMembershipDiscount',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.createMembershipDiscount(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const createMembershipPlan = createAsyncThunk(
  'membership/createMembershipPlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.createMembershipPlan(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updatePlan = createAsyncThunk(
  'membership/updatePlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.updatePlan(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deactivePlan = createAsyncThunk(
  'membership/deactivePlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.changePlanStatus(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getTransactionList = createAsyncThunk(
  'membership/getTransactionList',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.getTransactionList(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteMembershipDiscount = createAsyncThunk(
  'membership/deleteMembershipDiscount',
  async (id, { rejectWithValue }) => {
    try {
      return await api.deleteMembershipDiscount(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAutomaticStoreCredit = createAsyncThunk(
  'membership/updateAutomaticStoreCredit',
  async (payload) => {
    const response = await api.updateAutomaticStoreCredit(payload);
    return response;
  }
);

const resendEmail = createAsyncThunk('membership/resendEmail', async (data) => {
  const response = await api.resendEmail(data);
  return response;
});

const setMembershipCoupon = createAsyncThunk(
  'membership/setMembershipCoupon',
  async (payload) => {
    const response = await api.setMembershipCoupon(payload);
    return response;
  }
);
const createMembershipLoyalty = createAsyncThunk(
  'membership/createMembershipLoyalty',
  async (payload) => {
    const response = await api.createMembershipLoyalty(payload);
    return response;
  }
);

const getMemberShipCouponPlanList = createAsyncThunk(
  'membership/getMemberShipCouponPlanList',
  async (payload) => {
    const response = await api.getMemberShipCouponPlanList(payload);
    return response;
  }
);

const getMemberShipLoyaltyPlanList = createAsyncThunk(
  'membership/getMemberShipLoyaltyPlanList',
  async (payload) => {
    const response = await api.getMemberShipLoyaltyPlanList(payload);
    return response;
  }
);

const setMembershipCouponEnable = createAsyncThunk(
  'membership/setMembershipCouponEnable',
  async ({ id, enable }) => {
    const response = await api.setMembershipCouponEnable(id, enable);
    return response;
  }
);
const setMembershipLoyaltyEnable = createAsyncThunk(
  'membership/setMembershipLoyaltyEnable',
  async ({ id, enable }) => {
    const response = await api.setMembershipLoyaltyEnable(id, enable);
    return response;
  }
);

const deleteMembershipCouponPlan = createAsyncThunk(
  'membership/deleteMembershipCouponPlan',
  async (payload) => {
    const response = await api.deleteMembershipCouponPlan(payload);
    return response;
  }
);

const getMembershipFreeItemConfigs = createAsyncThunk(
  'membership/getMembershipFreeItemConfigs',
  async () => {
    const response = await api.getMembershipFreeItemConfigs();
    return response;
  }
);

const createMembershipFreeItemConfig = createAsyncThunk(
  'membership/createMembershipFreeItemConfig',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.createMembershipFreeItemConfig(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMembershipPerks = createAsyncThunk(
  'membership/getMembershipPerks',
  async (limit) => {
    const response = await api.getMembershipPerks(limit);
    return response;
  }
);
const updateMembershipPerks = createAsyncThunk(
  'membership/getMembershipPerks',
  async (limit) => {
    const response = await api.updateMembershipPerks(limit);
    return response;
  }
);

const downloadPerksCSV = createAsyncThunk(
  'membership/downloadPerksCSV',
  async () => {
    const response = await api.getMembershipPerks({ limit: 1000 });
    return response;
  }
);

const createMemberShip = createAsyncThunk(
  'memberships/add-membership',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.createMemberShip(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const addSecondaryMemberShip = createAsyncThunk(
  'memberships/addSecondaryMemberShip',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.addSecondaryMemberShip(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const validateMembershipCode = createAsyncThunk(
  'memberships/validateMembershipCode',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.validateMembershipCode(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      configs: {},
      isLoading: false,
      imgIsLoading: false,
      plans: [],
      allPlans: [],
      couponPlan: [],
      checkIn: [],
      memberships: [],
      storeCredits: [],
      sharedMembershipConfigs: {},
      discounts: [],
      perkConfigs: [],
      perks: [],
      total: 0,
      totalCouponPlans: 0,
      csv: [],
      error: null,
      loyaltyPlans: {
        data: [],
        total: 0,
      },
    },
    extraReducers: {
      [getMerchantMembershipConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantMembershipConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: getOr({}, 'payload', action),
        // plans: getOr([], 'payload.plans', action)
        //   .filter((p) => p.status === 1)
        //   .sort((a, b) => (a.order > b.order ? 1 : -1)),
      }),
      [getMerchantMembershipConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipPlans.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipPlans.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        plans: (getOr([], 'payload', action) || []).filter(
          (p) => p.status === 1
        ),
        allPlans: getOr([], 'payload', action) || [],
      }),
      [getMembershipPlans.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getCheckInList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getCheckInList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        checkIn: getOr([], 'payload', action),
      }),
      [getCheckInList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        memberships: getOr([], 'payload', action),
      }),
      [getMembershipList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipPlanStoreCreditList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipPlanStoreCreditList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        storeCredits: getOr([], 'payload', action),
      }),
      [getMembershipPlanStoreCreditList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipDiscountsList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipDiscountsList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        discounts: getOr([], 'payload', action),
      }),
      [getSharedMembershipConfigs.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getSharedMembershipConfigs.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getSharedMembershipConfigs.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        sharedMembershipConfigs: getOr([], 'payload', action),
      }),
      [getMembershipDiscountsList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [createMembershipDiscount.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [createMembershipDiscount.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [createMembershipDiscount.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMemberShipCouponPlanList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMemberShipCouponPlanList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        couponPlans: getOr([], 'payload.data', action),
        totalCouponPlans: getOr(0, 'payload.total', action),
      }),
      [getMemberShipCouponPlanList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMemberShipLoyaltyPlanList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMemberShipLoyaltyPlanList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        loyaltyPlans: getOr({ data: [], total: 0 }, 'payload', action),
      }),
      [getMemberShipLoyaltyPlanList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [deleteMembershipDiscount().pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [deleteMembershipDiscount.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [deleteMembershipDiscount.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [downloadCheckInCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Member Number': r.code,
          'Member First Name': r.firstName,
          'Member Last Name': r.lastName,
          'Member Email': r.email,
          'Member Phone': r.phone,
          'Check In Time': moment(`${r.checkInTime}Z`).format(
            'MMM Do YYYY, HH:mm'
          ),
        })),
      }),
      [downloadMembershipCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Number #': r.code,
          'First Name': r.firstName,
          'Last Name': r.lastName,
          Plan: r.plan?.name,
          Email: r.email,
          Phone: r.phone,
          'Expiration Date':
            r.expirationDate &&
            moment(`${r.expirationDate}Z`).format('MMM Do YYYY'),
          Status: membershipStatuses[r.status] || 'Deactivated',
        })),
      }),
      [getMembershipFreeItemConfigs.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipFreeItemConfigs.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        perkConfigs: getOr([], 'payload', action),
      }),
      [getMembershipFreeItemConfigs.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [createMembershipFreeItemConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [createMembershipFreeItemConfig.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [createMembershipFreeItemConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipPerks.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipPerks.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        perks: getOr([], 'payload', action),
      }),
      [getMembershipPerks.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [downloadPerksCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Number #': r.code,
          'First Name': r.firstName,
          'Last Name': r.lastName,
          'Free Item / Perk': r.description,
          Quantity: r.quantity,
          'Date Time': moment(`${r.createdAt}Z`).format('MMM Do YYYY, HH:mm'),
          Notes: r.note,
        })),
      }),
    },
  }),
};

const selectors = {
  selectMembershipConfig: (state) => getOr({}, 'configs', state[name]),
  selectIsLoading: (state) => getOr({}, 'isLoading', state[name]),
  selectPlans: (state) => getOr({}, 'plans', state[name]),
  selectAllPlans: (state) => getOr({}, 'allPlans', state[name]),
  selectMembershipList: (state) => getOr({}, 'memberships', state[name]),
  selectSharedMembershipConfig: (state) =>
    getOr({}, 'sharedMembershipConfigs', state[name]),
  selectStoreCreditsList: (state) => getOr({}, 'storeCredits', state[name]),
  selectDiscountsList: (state) => getOr({}, 'discounts', state[name]),
  selectFreeItemConfigs: (state) => getOr({}, 'perkConfigs', state[name]),
  selectCheckInList: (state) => getOr({}, 'checkIn', state[name]),
  selectCsv: (state) => getOr({}, 'csv', state[name]),
  selectMembershipLoyaltyPlan: (state) =>
    getOr({ data: [], total: 0 }, 'loyaltyPlans', state[name]),
  selectMembershipCouponPlan: (state) => getOr([], 'couponPlans', state[name]),
  selectMembershipCouponPlanTotal: (state) =>
    getOr(0, 'totalCouponPlans', state[name]),
  selectPerks: (state) => getOr({}, 'perks', state[name]),
};

export default {
  actions: {
    ...actions,
    getMerchantMembershipConfig,
    setMerchantMembershipConfig,
    setSponsorImage,
    setAutomaticStoreCredit,
    updateAutomaticStoreCredit,
    getMembershipPlans,
    getCheckInList,
    downloadCheckInCSV,
    downloadMembershipCSV,
    getMembershipList,
    getSharedMembershipConfigs,
    getMembershipPlanStoreCreditList,
    getMembershipDiscountsList,
    createMembershipDiscount,
    createMembershipPlan,
    deleteMembershipDiscount,
    resendEmail,
    setMembershipCoupon,
    createMembershipLoyalty,
    getMemberShipCouponPlanList,
    getMemberShipLoyaltyPlanList,
    setMembershipCouponEnable,
    setMembershipLoyaltyEnable,
    deleteMembershipCouponPlan,
    getMembershipFreeItemConfigs,
    createMembershipFreeItemConfig,
    getMembershipPerks,
    updateMembershipPerks,
    downloadPerksCSV,
    updatePlan,
    deactivePlan,
    getTransactionList,
    createMemberShip,
    validateMembershipCode,
    addSecondaryMemberShip,
  },
  selectors,
  reducer,
  name,
};
