import api from '../../utils/apiFetch';

const path = 'tickets';

export default {
  setTicketConfig: (payload) => api.post(`${path}`, payload),
  getTicketConfig: () => api.get(`${path}/config`),
  getTicketVenues: (params) =>
    api.get(`${path}/venues?${api.serialize(params)}`),
  getTicketTypes: (params) => api.get(`${path}/types?${api.serialize(params)}`),
  getTicketEvents: (params) =>
    api.get(`${path}/events?${api.serialize(params)}`),
  getTickets: (params) => api.get(`${path}/tickets?${api.serialize(params)}`),
  getOrders: (params) => api.get(`${path}/orders?${api.serialize(params)}`),
  getOrderItems: (params) => api.get(`${path}/order-items/${params}`),
  getTicketPricing: (params) =>
    api.get(`${path}/pricing?${api.serialize(params)}`),
  updateStatusTicketConfig: (id, payload) =>
    api.post(`${path}/${id}/update-status`, payload),
  setDefaultTicketConfig: (id) => api.post(`${path}/${id}/set-default`),
  addVenue: (payload) => api.post(`${path}/venues`, payload),
  postTicketType: (payload) => api.post(`${path}/types`, payload),
  postTicketEvent: (payload) => api.post(`${path}/events`, payload),
  duplicateTicketEvent: (payload) =>
    api.post(`${path}/events/duplicate`, payload),
  postTicketPricing: (payload) => api.post(`${path}/pricing`, payload),
  getEventsDiscountsList: (payload) =>
    api.get(`${path}/events-discount/${payload}`),
  setEventsDiscounts: (payload) => api.post(`${path}/event-discount`, payload),
  editEventsDiscounts: (payload) => api.put(`${path}/event-discount`, payload),
  editTicket: (id, payload) => api.put(`${path}/edit-ticket`, payload),
  deleteTicketEvent: (id) => api.delete(`${path}/events/${id}`),
  resendEmail: (payload) => api.get(`${path}/resend-email/${payload}`),
};
