import api from '../../utils/apiFetch';

const path = 'accounts';

export default {
  login: (payload) => api.post(`${path}/authenticate`, payload),
  refreshToken: () => api.post(`${path}/refresh-token`),
  logout: () => api.post(`${path}/revoke-token`),
  register: (payload) => api.post(`auth/register`, payload),
  resendCode: (payload) => api.post(`auth/resend`, payload),
  forgotPassword: (payload) => api.post(`auth/forgot-password`, payload),
  resetPassword: (payload) => api.post(`auth/reset-password`, payload),
  verifyEmail: (payload) => api.post(`auth/verify-email`, payload),
  posAuth: () => api.get(`auth/square-merchant-authorize`),
  loginByCode: (payload) => api.post(`auth/code`, payload),
  getShopifyUrl: (payload) =>
    api.get(`auth/shopify-authorize?${api.serialize(payload)}`),
};
