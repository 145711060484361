import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'transactions';

const getMerchantTransactions = createAsyncThunk(
  'transactions/getMerchantTransactions',
  async (params) => {
    const response = await api.getMerchantTransactions(params);
    return response;
  }
);
const getMerchantStoreCreditTransactions = createAsyncThunk(
  'transactions/getMerchantStoreCreditTransactions',
  async (params) => {
    const response = await api.getMerchantStoreCreditTransactions(params);
    return response;
  }
);

const getMembershipTransactions = createAsyncThunk(
  'transactions/getMembershipTransactions',
  async (params) => {
    const response = await api.getMembershipTransactions(params);
    return response;
  }
);

const resendEmail = createAsyncThunk(
  'transactions/resendEmail',
  async (params) => {
    const response = await api.resendEmail(params);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      isSending: false,
      error: {},
      transactions: [],
    },
    extraReducers: {
      [getMerchantTransactions.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantTransactions.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        transactions: getOr([], 'payload', action),
      }),
      [getMerchantTransactions.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMerchantStoreCreditTransactions.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantStoreCreditTransactions.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        transactions: getOr([], 'payload', action),
      }),
      [getMerchantStoreCreditTransactions.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipTransactions.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipTransactions.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        transactions: getOr([], 'payload', action),
      }),
      [getMembershipTransactions.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [resendEmail.pending]: (state) => ({
        ...state,
        isSending: true,
      }),
      [resendEmail.fulfilled]: (state) => ({
        ...state,
        isSending: false,
      }),
      [resendEmail.rejected]: (state, payload) => ({
        ...state,
        isSending: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectMerchantTransactions: (state) =>
    getOr({ data: [] }, 'transactions', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectIsSending: (state) => getOr(false, 'isSending', state[name]),
};

export default {
  actions: {
    ...actions,
    getMerchantTransactions,
    getMerchantStoreCreditTransactions,
    getMembershipTransactions,
    resendEmail,
  },
  selectors,
  reducer,
  name,
};
