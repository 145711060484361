import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { alert } from '../../state';
import i18n from '../../i18n';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AlertBar = ({ message, severity, open, closeAlert, clearAlert }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeAlert(false);
    setTimeout(() => clearAlert(), 1000);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
AlertBar.defaultProps = {
  message: i18n.t('somethingWentWrong'),
};

AlertBar.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeAlert: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  severity: alert.selectors.selectSeverity(state),
  message: alert.selectors.selectMessage(state),
  open: alert.selectors.selectOpen(state),
});
const mapDispatchToProps = (dispatch) => ({
  closeAlert: (payload) => dispatch(alert.actions.close(payload)),
  clearAlert: () => dispatch(alert.actions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertBar);
