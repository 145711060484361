/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';

const name = 'coupons';

const getCouponConfig = createAsyncThunk(
  'coupons/getMerchantCouponConfig',
  async () => {
    const response = await api.getMerchantCouponConfig();
    return response;
  }
);

const setCouponConfig = createAsyncThunk(
  'coupons/setMerchantCouponConfig',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.setMerchantCouponConfig(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setImageCouponConfig = createAsyncThunk(
  'coupons/setImageCouponConfig',
  async (payload) => {
    const response = await api.setImageCouponConfig(payload);
    return response;
  }
);

const getCouponPlanList = createAsyncThunk(
  'coupons/getCouponPlanList',
  async (payload) => {
    const response = await api.getCouponPlanList(payload);
    return response;
  }
);

const getCouponList = createAsyncThunk(
  'coupons/getCouponList',
  async (payload) => {
    const response = await api.getCouponList(payload);
    return response;
  }
);
const downloadCouponCSV = createAsyncThunk(
  'coupons/downloadCouponCSV',
  async (params) => {
    const response = await api.getCouponList({ ...params, limit: 99999 });
    return response;
  }
);

const createCouponPlan = createAsyncThunk(
  'coupons/createCouponPlan',
  async (payload) => {
    const response = await api.createCouponPlan(payload);
    return response;
  }
);

const createSingleCoupon = createAsyncThunk(
  'coupons/createSingleCoupon',
  async (payload) => {
    const response = await api.createSingleCoupon(payload);
    return response;
  }
);
const sendSingleCoupon = createAsyncThunk(
  'coupons/sendSingleCoupon',
  async (payload) => {
    const response = await api.sendSingleCoupon(payload);
    return response;
  }
);

const deleteCouponPlan = createAsyncThunk(
  'coupons/deleteCouponPlan',
  async (payload) => {
    const response = await api.deleteCouponPlan(payload);
    return response;
  }
);

const resendEmail = createAsyncThunk('coupons/resendEmail', async (id) => {
  const response = await api.resendEmail(id);
  return response;
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      configs: {},
      isLoading: false,
      imgIsLoading: false,
      plans: [],
      totalPlans: 0,
      checkIn: [],
      coupons: { data: [], total: 0 },
      error: null,
      csv: [],
    },
    extraReducers: {
      [getCouponConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getCouponConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: getOr(null, 'payload', action),
      }),
      [getCouponConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),

      [setCouponConfig.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [setCouponConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: getOr(null, 'payload', action),
      }),
      [setCouponConfig.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),

      [setImageCouponConfig.pending]: (state) => ({
        ...state,
        imgIsLoading: true,
      }),
      [setImageCouponConfig.fulfilled]: (state) => ({
        ...state,
        imgIsLoading: false,
      }),
      [setImageCouponConfig.rejected]: (state, payload) => ({
        ...state,
        imgIsLoading: false,
        error: payload,
      }),

      [getCouponPlanList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getCouponPlanList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        plans: getOr([], 'payload.data', action),
        totalPlans: getOr(0, 'payload.total', action),
      }),
      [getCouponPlanList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getCouponList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getCouponList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        coupons: getOr({ total: 0, data: [] }, 'payload', action),
      }),
      [getCouponList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [downloadCouponCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Issue Date': moment(`${r.createdAt}Z`).format(
            'MMM DD YYYY, HH:mm:ss'
          ),
          'First Name': r.firstName,
          'Last Name': r.lastName,
          Email: r.email,
          'Coupon Code': r.code,
          'Max Redemptions': r.maxRedemptions === -1 ? '' : r.maxRedemptions,
          'Expiration Date': r.expirationDate
            ? moment(`${r.expirationDate}Z`).format('MMM DD YYYY, HH:mm:ss')
            : '',
          'Discount Name': r.couponPlan.name,
          'Discount Description': r.discount,
          'Campaign Name': r.description,
        })),
      }),
    },
  }),
};

const selectors = {
  selectCouponConfig: (state) => getOr({}, 'configs', state[name]),
  selectCouponList: (state) =>
    getOr({ data: [], total: 0 }, 'coupons', state[name]),
  selectIsLoading: (state) => getOr({}, 'isLoading', state[name]),
  selectCsv: (state) => getOr({}, 'csv', state[name]),
  selectImgIsLoading: (state) => getOr(false, 'imgIsLoading', state[name]),
  selectCouponPlans: (state) => getOr([], 'plans', state[name]),
  selectCouponPlansTotal: (state) => getOr(0, 'totalPlans', state[name]),
};

export default {
  actions: {
    ...actions,
    getCouponConfig,
    setCouponConfig,
    setImageCouponConfig,
    getCouponPlanList,
    getCouponList,
    createCouponPlan,
    deleteCouponPlan,
    downloadCouponCSV,
    createSingleCoupon,
    sendSingleCoupon,
    resendEmail,
    ///
  },
  selectors,
  reducer,
  name,
};
