// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-clover-js": () => import("./../../../src/pages/clover.js" /* webpackChunkName: "component---src-pages-clover-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-square-js": () => import("./../../../src/pages/square.js" /* webpackChunkName: "component---src-pages-square-js" */),
  "component---src-pages-verify-registration-js": () => import("./../../../src/pages/verify-registration.js" /* webpackChunkName: "component---src-pages-verify-registration-js" */),
  "component---templates-legal-js": () => import("./../../../templates/legal.js" /* webpackChunkName: "component---templates-legal-js" */)
}

