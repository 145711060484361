import auth from './auth';
import alert from './alert';
import merchant from './merchant';
import stats from './stats';
import payouts from './payouts';
import transactions from './transactions';
import categories from './categories';
import membership from './membership';
import storeCredit from './storeCredit';
import loyalty from './loyalty';
import giftCard from './giftCard';
import ticket from './ticket';
import coupons from './coupons';

export default {
  auth: auth.reducer,
  alert: alert.reducer,
  merchant: merchant.reducer,
  stats: stats.reducer,
  payouts: payouts.reducer,
  transactions: transactions.reducer,
  categories: categories.reducer,
  membership: membership.reducer,
  storeCredit: storeCredit.reducer,
  loyalty: loyalty.reducer,
  giftCard: giftCard.reducer,
  ticket: ticket.reducer,
  coupons: coupons.reducer,
};
