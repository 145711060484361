/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'categories';

const getLocalBusinessCategory = createAsyncThunk(
  'categories/getLocalBusinessCategory',
  async () => {
    const response = api.getLocalBusinessCategory();
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      category: [],
    },
    extraReducers: {
      [getLocalBusinessCategory.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getLocalBusinessCategory.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        category: action.payload,
      }),
      [getLocalBusinessCategory.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectLocalBusinessCategory: (state) => getOr([], 'category', state[name]),
};

export default {
  actions: {
    ...actions,
    getLocalBusinessCategory,
  },
  selectors,
  reducer,
  name,
};
