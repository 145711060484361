import i18n from '../i18n';

const FREQUENCY = [
  {
    value: 'Weekly',
    label: 'Weekly',
    note:
      'Store credit will be issued every Sunday and expire on end of day Saturday',
    exprires: 7,
  },
  {
    value: 'TwiceAMonth',
    label: 'Twice a month',
    note: 'Store credit will be issued on the 1ˢᵗ and 16ᵗʰ of every month.',
    exprires: '15th and Last day of the month',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
    note: 'Store credit will be issued on the 1ˢᵗ of every month',
    exprires: 'Last day of the month',
  },
  {
    value: 'Quarterly',
    label: 'Quarterly',
    note:
      'Store credit will be issued on Jan 1ˢᵗ, April 1ˢᵗ, July 1ˢᵗ and Oct 1ˢᵗ',
    exprires: 'March 30ᵗʰ, June 30ᵗʰ, September 30th and Dec 31ˢᵗ',
  },
  {
    value: 'Annually',
    label: 'Annually',
    note:
      'Store credit will be issued annually from the day the membership was registered',
    exprires: '',
  },
];

const FREQUENCY_NAME = {
  0: 'Weekly',
  1: 'TwiceAMonth',
  2: 'Monthly',
  3: 'Quarterly',
  4: 'Annually',
};

const membershipStatuses = [
  'Active',
  'Canceled',
  'Pending',
  'Deactivated',
  'Expired',
];

const PartnerEnum = {
  BHN: 'BHN',
  Square: 'Square',
  Clover: 'Clover',
  Shopify: 'Shopify',
};

const TARGET_TYPE = {
  Category: 0,
  Item: 1,
  WholePurchase: 2,
};

// eslint-disable-next-line import/prefer-default-export
export {
  FREQUENCY,
  FREQUENCY_NAME,
  membershipStatuses,
  PartnerEnum,
  TARGET_TYPE,
};
export const dashboards = [
  {
    name: i18n.t('giftCards.giftCards'),
    id: 0,
    link: 'gift-card',
    show: true,
  },
  { name: i18n.t('loyalty.loyalty'), id: 1, link: 'loyalty', show: true },
  {
    name: i18n.t('membership.memberships'),
    id: 2,
    link: 'membership',
    show: true,
  },
  {
    name: i18n.t('storeCredit.storeCredits'),
    id: 3,
    link: 'store-credit',
    show: true,
  },
  { name: i18n.t('coupons.coupons'), id: 4, link: 'coupons', show: true },
  { name: i18n.t('tickets.tickets'), id: 5, link: 'ticket', show: true },
];
