/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import api from './api';

const name = 'loyalty';

const getNewLoyaltyProgram = createAsyncThunk(
  'loyalty/getNewLoyaltyProgram',
  async () => {
    const response = await api.getNewLoyaltyProgram();
    return response;
  }
);

const getProgramList = createAsyncThunk(
  'loyalty/getProgramList',
  async (limit) => {
    const response = await api.getProgramList(limit);
    return response;
  }
);

const getLoyaltyList = createAsyncThunk(
  'loyalty/getLoyaltyList',
  async (limit) => {
    const response = await api.getLoyaltyList(limit);
    return response;
  }
);

const getItems = createAsyncThunk('loyalty/getItems', async (limit) => {
  const response = await api.getItems(limit);
  return response;
});

const getItemsByCategory = createAsyncThunk(
  'loyalty/getItemsByCategory',
  async (categories) => {
    const response = await api.getItemsByCategory(categories);
    return response;
  }
);

const downloadLoyaltyCSV = createAsyncThunk(
  'loyalty/downloadLoyaltyCSV',
  async () => {
    const response = await api.getLoyaltyList({ limit: 2500 });
    return response;
  }
);

const uploadLoyaltyCSV = createAsyncThunk(
  'loyalty/uploadLoyaltyCSV',
  async (file) => {
    const response = await api.uploadLoyaltyCSV(file);
    return response;
  }
);

const saveLoyaltyProgram = createAsyncThunk(
  'loyalty/saveLoyaltyProgram',
  async (payload) => {
    const response = await api.saveLoyaltyProgram(payload);
    return response;
  }
);

const editLoyaltyProgram = createAsyncThunk(
  'loyalty/editLoyaltyProgram',
  async (payload) => {
    const response = await api.saveLoyaltyProgram(payload);
    return response;
  }
);

const setEditProgram = createAsyncThunk(
  'loyalty/setEditProgram',
  async (payload) => {
    return payload;
  }
);

const getLoyaltyTransactions = createAsyncThunk(
  'loyalty/getLoyaltyTransactions',
  async (params) => {
    const response = await api.getLoyaltyTransactions(params);
    if (response?.data) {
      response.data = response.data.map((p) => {
        switch (p.type) {
          case 0:
            p.pointsEarned = p.points;
            break;
          case 1:
            p.pointsRedeemed = p.points;
            break;
          default:
            p.pointsEarned = p.points;
            break;
        }
        return p;
      });
    }
    return response;
  }
);

const resendEmail = createAsyncThunk('loyalty/resendEmail', async (params) => {
  const response = await api.resendEmail(params);
  return response;
});

const dateFormat = (row) =>
  row ? moment(`${row}Z`).format('YYYY-MM-DD HH:mm:ss') : '';

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      program: {},
      editProgram: {},
      isLoading: false,
      imgIsLoading: false,
      items: [],
      checkIn: [],
      programs: [],
      loyalties: [],
      total: 0,
      csv: [],
      error: null,
      loyaltyTransactions: {
        isLoading: false,
        total: 0,
        data: [],
      },
      isSending: false,
    },
    extraReducers: {
      [getProgramList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getProgramList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        programs: getOr([], 'payload', action),
      }),
      [getProgramList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getLoyaltyList.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getLoyaltyList.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        loyalties: getOr([], 'payload', action),
      }),
      [getLoyaltyList.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getNewLoyaltyProgram.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getNewLoyaltyProgram.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        program: getOr({}, 'payload', action),
      }),
      [getNewLoyaltyProgram.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [saveLoyaltyProgram.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [saveLoyaltyProgram.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        program: getOr({}, 'payload', action),
      }),
      [saveLoyaltyProgram.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [setEditProgram.fulfilled]: (state, action) => ({
        ...state,
        editProgram: getOr({}, 'payload', action),
      }),
      [editLoyaltyProgram.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [editLoyaltyProgram.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        editProgram: getOr({}, 'payload', action),
      }),
      [editLoyaltyProgram.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getItems.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getItems.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        items: getOr([], 'payload', action),
      }),
      [getItems.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getItemsByCategory.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getItemsByCategory.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        // items: getOr([], 'payload', action),
      }),
      [getItemsByCategory.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [uploadLoyaltyCSV.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [uploadLoyaltyCSV.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [uploadLoyaltyCSV.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [downloadLoyaltyCSV.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        csv: getOr([], 'payload.data', action).map((r) => ({
          'Loyalty Program Name': r.programName,
          'Loyalty Number': r.code,
          'First Name': r.firstName,
          'Last Name': r.lastName,
          'Email Address': r.email,
          'Mobile Number': r.phone,
          'Birthday Month': Number(r.birthMonth)
            ? moment()
                .month(Number(r.birthMonth) - 1)
                .format('MMM')
            : '',
          'Current Points': r.point,
          'Total Points Earned': r.totalPoint,
          'Total Rewards Earned': r.rewardedTimes,
          'Total Rewards Redeemed': r.redeemedTimes,
          'Last Points Earned (YYYY-MM-DD HH:MM:SS)': dateFormat(r.lastEarned),
          'Last Reward Earned (YYYY-MM-DD HH:MM:SS)': dateFormat(r.lastReward),
          'Last Reward Redeemed (YYYY-MM-DD HH:MM:SS)': dateFormat(
            r.lastRedeemed
          ),
          'Sign Up Date (YYYY-MM-DD HH:MM:SS)': dateFormat(r.createdAt),
        })),
      }),
      [getLoyaltyTransactions.pending]: (state) => ({
        ...state,
        loyaltyTransactions: { ...state.loyaltyTransactions, isLoading: true },
      }),
      [getLoyaltyTransactions.fulfilled]: (state, action) => ({
        ...state,
        loyaltyTransactions: { ...action.payload, isLoading: false },
      }),
      [getLoyaltyTransactions.rejected]: (state, payload) => ({
        ...state,
        loyaltyTransactions: { ...state.loyaltyTransactions, isLoading: true },
        error: payload,
      }),
      [resendEmail.pending]: (state) => ({
        ...state,
        isSending: true,
      }),
      [resendEmail.fulfilled]: (state) => ({
        ...state,
        isSending: false,
      }),
      [resendEmail.rejected]: (state, payload) => ({
        ...state,
        isSending: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectLoyaltyProgram: (state) => getOr([], 'program', state[name]),
  editLoyaltyModal: (state) => getOr([], 'editProgram', state[name]),
  selectIsLoading: (state) => getOr({}, 'isLoading', state[name]),
  selectItems: (state) => getOr([], 'items', state[name]),
  selectProgramList: (state) => getOr({}, 'programs', state[name]),
  selectLoyaltyList: (state) => getOr({}, 'loyalties', state[name]),
  selectCsv: (state) => getOr({}, 'csv', state[name]),
  selectLoyaltyTransactions: (state) =>
    getOr({ data: [], total: 0 }, 'loyaltyTransactions', state[name]),
  selectIsSending: (state) => getOr(false, 'isSending', state[name]),
};

export default {
  actions: {
    ...actions,
    saveLoyaltyProgram,
    editLoyaltyProgram,
    setEditProgram,
    getNewLoyaltyProgram,
    downloadLoyaltyCSV,
    uploadLoyaltyCSV,
    getProgramList,
    getLoyaltyList,
    getItems,
    getItemsByCategory,
    getLoyaltyTransactions,
    resendEmail,
  },
  selectors,
  reducer,
  name,
};
